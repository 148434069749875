import ChefCarousel from "@/components/ChefCarousel";
import DraggableScrollArea from "@/components/DraggableScrollArea";
import EntryThumbnailCompact from "@/components/EntryThumbnailCompact";
import RailContainer from "@/components/RailContainer";
import { useMenuContext } from "@/contexts/MenuContext";
import { quickSearchItemClicked } from "@/gtmEvents/mainMenu";
import useCurrentUser from "@/hooks/useCurrentUser";
import { createSearchHref } from "@/lib/algoliaUtils";
import { footerNavigation } from "@/lib/navigation";
import pushToDataLayer from "@/lib/pushToDataLayer";
import * as Dialog from "@radix-ui/react-dialog";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { usePreventScroll } from "react-aria";

const MainMenu = ({ items = [], theme = "light" }) => {
  const router = useRouter();

  const { isLoggedIn } = useCurrentUser();

  const { isOpen, setIsOpen } = useMenuContext();

  usePreventScroll({ isDisabled: !isOpen });

  // When the route finishes changing, close the navigation
  useEffect(() => {
    const handleRouteChange = () => {
      setIsOpen(false);
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events, setIsOpen]);

  return (<>
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen} modal={false}>
      <Dialog.Portal>
        <div
          className={clsx("MainMenu__overlay", {
            "top-header fixed inset-x-0 bottom-0 z-[50] hidden bg-zinc-800 bg-opacity-75 sm:block":
              isOpen,
            hidden: !isOpen,
          })}
          onClick={() => setIsOpen(false)}
        />
        <Dialog.Content
          className={clsx(
            "MainMenu__content",
            "top-header fixed bottom-0 right-0 z-[51] w-full border-t bg-white text-zinc-950 focus:focus-visible:outline-none sm:w-2/3 md:w-1/2 md:bg-white lg:w-2/5 2xl:w-2/5"
          )}
          onInteractOutside={(e) => e.preventDefault()}
        >
          <div
            className={clsx(
              "MainMenu__scrollArea",
              "h-dvh-ex-header relative overflow-y-scroll scrollbar-none"
            )}
          >
            <div className={clsx("MainMenu__viewport", "pt-6")}>
              <div className={clsx("MainMenu__topLevelNav", "flex flex-col")}>
                {items.map((item) => {
                  if (item.typeHandle === "quickSearch") {
                    return (
                      <_QuickSearch
                        key={item.id}
                        options={item.options}
                        theme={theme}
                      />
                    );
                  }

                  if (item.typeHandle === "chefsItem") {
                    return (
                      <_ChefsItem
                        key={item.id}
                        heading={item.heading}
                        chefs={item.chefs}
                        theme={theme}
                      />
                    );
                  }

                  if (item.typeHandle === "railItem") {
                    return (
                      <_RailItem
                        key={item.id}
                        heading={item.heading}
                        rail={item.rail?.[0]}
                        linkHref={item.linkHref}
                        linkLabel={item.linkLabel}
                        theme={theme}
                      />
                    );
                  }

                  if (item.typeHandle === "linkItem") {
                    // If the item href is /signin and the user is logged in, don't show it
                    if (item.href === "/signin" && isLoggedIn) return null;

                    return (
                      (<Link
                        key={item.id}
                        href={item.href}
                        className={clsx(
                          "border-b px-6 py-6 text-sm lg:px-12 lg:py-8",
                          {
                            "border-b-zinc-700": theme === "dark",
                            "border-b-zinc-200": theme === "light",
                          }
                        )}>

                        {item.label}

                      </Link>)
                    );
                  }

                  return null;
                })}
              </div>
              <div className={clsx("MainMenu__secondaryNav", "mt-auto")}>
                <_SecondaryNav theme={theme} />
              </div>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  </>);
};

const _QuickSearch = ({ options, theme = "dark" }) => {
  return (
    (<div
      className={clsx(
        "flex flex-col space-y-4 border-b px-6 pb-6 pt-0 lg:space-y-5 lg:px-12 lg:pt-3",
        {
          "border-b-zinc-700": theme === "dark",
          "border-b-zinc-200": theme === "light",
        }
      )}
    >
      <h3 className={clsx("font-spatial text-lg xl:text-xl")}>
        <Link href={"/search"}>
          Quick search
        </Link>
      </h3>
      <ul className={clsx("flex flex-wrap")}>
        {options.map((option) => {
          let href = "/search";

          // Generate a search url based on the option
          if (option.criteriaType === "query") {
            href = createSearchHref({
              query: option.criteriaValue,
            });
          } else {
            href = createSearchHref({
              [`${option.criteriaType}`]: option.criteriaValue,
            });
          }

          return (
            <li key={option.id} className={clsx("mb-2.5 mr-2.5")}>
              <_Pill
                href={href}
                onClick={() =>
                  pushToDataLayer(quickSearchItemClicked, {
                    quickSearchItem: option.label,
                  })
                }
                theme={theme}
              >
                {option.label}
              </_Pill>
            </li>
          );
        })}
      </ul>
    </div>)
  );
};

const _ChefsItem = ({ heading, chefs, theme = "dark" }) => {
  return (
    (<div
      className={clsx("space-y-4 border-b py-6 lg:space-y-5 lg:py-8", {
        "border-b-zinc-700": theme === "dark",
        "border-b-zinc-200": theme === "light",
      })}
    >
      <div className={clsx("flex items-center px-6 lg:px-12")}>
        <Link
          href={"/chefs"}
          className={clsx(
            "font-spatial text-lg transition ease-out hover:opacity-75 xl:text-xl"
          )}>

          {heading}

        </Link>
        <_Pill href={"/chefs"} theme={theme} className={clsx("ml-auto")}>
          See All
        </_Pill>
      </div>
      <div className={clsx("relative")}>
        <ChefCarousel
          chefs={chefs}
          size="sm"
          theme={theme === "dark" ? "light" : "dark"}
          className={clsx("px-6 lg:px-12")}
        />

        <div
          className={clsx(
            "pointer-events-none absolute inset-y-0 right-0 z-10 h-full w-16 bg-gradient-to-r from-transparent",
            {
              "to-zinc-950/50": theme === "dark",
              "to-white/50": theme === "light",
            }
          )}
        ></div>
      </div>
    </div>)
  );
};

const _RailItem = ({ heading, rail, linkHref, linkLabel, theme = "dark" }) => {
  // Get the current day, expressed as a number
  const currentDay = new Date().getDay();

  return (
    (<div
      className={clsx("space-y-4 border-b py-6 lg:space-y-5 lg:py-8", {
        "border-b-zinc-700": theme === "dark",
        "border-b-zinc-200": theme === "light",
      })}
    >
      <div className={clsx("flex items-center px-6 lg:px-12")}>
        <Link
          href={linkHref}
          className={clsx(
            "font-spatial text-lg transition ease-out hover:opacity-75 xl:text-xl"
          )}>

          {heading}

        </Link>
        <_Pill href={linkHref} theme={theme} className={clsx("ml-auto")}>
          {linkLabel}
        </_Pill>
      </div>
      <div className={clsx("overflow-hidden")}>
        {rail.typeHandle === "editorialRail" ? (
          <_Carousel entries={rail.recipes} theme={theme} />
        ) : (
          <RailContainer
            key={rail.id}
            rail={rail}
            hitsPerPage={24}
            shuffleSeed={currentDay}
            entriesToShow={12}
            renderLoading={() => {
              return <_Carousel theme={theme} />;
            }}
            render={(data) => {
              const entries = data.hits;

              return <_Carousel entries={entries} theme={theme} />;
            }}
          />
        )}
      </div>
    </div>)
  );
};

const _Carousel = ({ entries = [], theme = "dark" }) => {
  // if there are no entries, create 12 dummy entries
  if (entries.length === 0) {
    for (let i = 0; i < 12; i++) {
      entries.push({
        id: i,
        title: "",
        summary: "",
        sectionHandle: "loading",
      });
    }
  }

  return (
    <div className={clsx("relative w-full")}>
      <DraggableScrollArea enableSnap={false}>
        <div className={clsx("flex space-x-6 px-6 lg:px-12")}>
          {entries.map((entry, index) => {
            return (
              <div key={entry.id} className={clsx("w-40")}>
                <EntryThumbnailCompact
                  entry={entry}
                  priority={index === 0}
                  draggable={false}
                  selectable={false}
                />
              </div>
            );
          })}
        </div>
      </DraggableScrollArea>
      <div
        className={clsx(
          "pointer-events-none absolute inset-y-0 right-0 z-10 h-full w-16 bg-gradient-to-r from-transparent",
          {
            "to-zinc-950/50": theme === "dark",
            "to-white/50": theme === "light",
          }
        )}
      ></div>
    </div>
  );
};

const _SecondaryNav = ({ theme = "dark" }) => {
  const { isLoggedOut } = useCurrentUser();

  return (
    (<div className={clsx("px-6 py-6 lg:px-12 lg:py-8", {})}>
      <div className={clsx("grid grid-cols-2 gap-x-6 gap-y-2")}>
        {footerNavigation.map((item) => {
          // If the item is marked true for isLoggedOut and the user is logged in, don't show it
          if (!item.isLoggedOut && isLoggedOut) return null;

          return (
            (<Link
              key={item.href}
              href={item.href}
              className={clsx(
                "flex font-body text-sm transition ease-out hover:opacity-75",
                {
                  "text-zinc-800": theme === "dark",
                  "text-zinc-950": theme === "light",
                }
              )}>

              {item.label}

            </Link>)
          );
        })}
      </div>
    </div>)
  );
};

const _Pill = ({
  href,
  children,
  theme = "dark",
  onClick = () => {},
  className = "",
}) => {
  return (
    (<Link
      href={href}
      className={clsx(
        "h-component-sm flex items-center rounded-full px-3 font-body text-sm transition ease-out ",
        {
          "bg-zinc-100 text-zinc-950 hover:bg-zinc-200": theme === "light",
          "bg-zinc-50 text-zinc-950 hover:bg-zinc-300": theme === "dark",
        },
        className
      )}
      onClick={onClick}>

      {children}

    </Link>)
  );
};

export default observer(MainMenu);
