import {
  ArrowPathIcon,
  ArrowRightIcon,
  CheckIcon,
} from "@heroicons/react/24/outline";
import clsx from "clsx";
import { Field, Formik } from "formik";
import Link from "next/link";
import { useState } from "react";
import * as Yup from "yup";

const Newsletter = ({ source = "" }) => {
  const [hasConsented, setHasConsented] = useState(false);

  const handleConsent = (event) => {
    event.stopPropagation();

    setHasConsented((prev) => !prev);
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email address")
      .required("Your email address is required"),
  });

  const onSubmit = async (
    values,
    { setSubmitting, setStatus, setFieldValue, setFieldError }
  ) => {
    console.log("Submitting");

    setStatus(null);

    if (!hasConsented) {
      setFieldError("email", `Please consent to continue`);
      setSubmitting(false);
      return;
    }

    try {
      const response = await fetch("/api/newsletter/subscribe", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: values.email }),
      });

      const data = await response.json();

      if (response.ok) {
        // Handling the case where user is already subscribed or successfully subscribed
        setStatus(data.message);
        if (data.error === false) {
          // Clear the field only if it's a successful new subscription
          setFieldValue("email", "", false);
        }
      } else {
        // Handling other types of errors
        setFieldError("email", `${data.message}`);
      }
    } catch (error) {
      // Handling network or other unexpected errors
      setFieldError(
        "email",
        `There was an error: ${error.message || error.toString()}`
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    (<div className={clsx("Newsletter", "space-y-3")}>
      <div className={clsx("Newsletter__form", "")}>
        <Formik
          initialValues={{ email: "" }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnBlur={false}
        >
          {({
            isSubmitting,
            handleReset,
            handleSubmit,
            status,
            errors,
            touched,
          }) => (
            <form
              className={clsx("Form", "relative space-y-2")}
              onReset={handleReset}
              onSubmit={handleSubmit}
            >
              <div>
                <label>
                  <span className={clsx("hidden")}>Email address</span>

                  <Field
                    name="email"
                    placeholder="Enter your email address"
                    type="email"
                    className={clsx(
                      "h-12 w-full rounded-full bg-zinc-50 px-5 font-body text-sm text-zinc-900"
                    )}
                  />

                  {touched.email && errors.email && (
                    <div
                      className={clsx(
                        "px-5 py-4 text-center font-body text-sm"
                      )}
                    >
                      {errors.email}
                    </div>
                  )}
                </label>

                {status && (
                  <div
                    className={clsx(
                      "Newsletter__status",
                      "pt-3 font-body text-sm"
                    )}
                  >
                    {status}
                  </div>
                )}
                <button
                  type="submit"
                  className={clsx(
                    "Newsletter__button",
                    "absolute inset-y-0.5 right-0.5 flex h-11 w-11 items-center justify-center rounded-full bg-zinc-900 transition ease-out hover:bg-zinc-800"
                  )}
                  disabled={isSubmitting}
                  aria-label="Submit email address"
                  data-source={source}
                >
                  {isSubmitting ? (
                    <ArrowPathIcon className={clsx("h-5 w-5 animate-spin")} />
                  ) : (
                    <ArrowRightIcon className={clsx("h-5 w-5")} />
                  )}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
      <div
        className={clsx(
          "Newsletter__privacy",
          "flex cursor-pointer items-center space-x-3"
        )}
      >
        <button
          className={clsx(
            "Newsletter__consent",
            "group flex h-5 w-5 shrink-0 items-center justify-center rounded-full border-2 border-zinc-50 bg-zinc-950 transition ease-out hover:bg-zinc-50"
          )}
          type="button"
          aria-label="Newsletter opt-in"
          onClick={handleConsent}
        >
          {hasConsented && (
            <CheckIcon
              className={clsx(
                "h-3 w-3 stroke-zinc-50 stroke-[4] transition ease-out group-hover:stroke-zinc-950"
              )}
            />
          )}
        </button>
        <label
          className={clsx(
            "Newsletter__statement",
            "cursor-pointer font-body text-sm text-current"
          )}
          onClick={handleConsent}
        >
          I consent to being contacted by email. Your email address is safe with
          us. Read our{" "}
          <Link
            href="/privacy-policy"
            className={clsx(
              "underline decoration-zinc-50/50 decoration-from-font underline-offset-2"
            )}>
            
              Privacy Policy
            
          </Link>
          .
        </label>
      </div>
    </div>)
  );
};

export default Newsletter;
