import { useRouter } from "next/router";

const useIsHomepage = () => {
  const router = useRouter();

  const isHomepage = router.pathname === "/" || router.pathname === "/home";

  return isHomepage;
};

export default useIsHomepage;
