import posthog from "posthog-js";
import { createContext, useState, ReactNode, useEffect } from "react";
import useCurrentUser from "@/hooks/useCurrentUser";

type SessionContextProps = {
  sessionId: string | null;
  setSessionId: (id: string | null) => void;
  guestId: string | null;
  setGuestId: (id: string | null) => void;
  userId: string | null;
  setUserId: (id: string | null) => void;
  utmString: string | null;
  setUtmString: (utmString: string | null) => void;
  isAuthenticated: boolean;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
};

export const SessionContext = createContext<SessionContextProps>({
  sessionId: null,
  setSessionId: () => {},
  guestId: null,
  setGuestId: () => {},
  userId: null,
  setUserId: () => {},
  isAuthenticated: false,
  setIsAuthenticated: () => {},
  utmString: null,
  setUtmString: () => {},
});

type SessionProviderProps = {
  children: ReactNode;
};

export function SessionProvider({ children }: SessionProviderProps) {
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [guestId, setGuestId] = useState<string | null>(null);
  const [userId, setUserId] = useState<string | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [utmString, setUtmString] = useState<string | null>(null);
  const { currentUser, isFetching } = useCurrentUser();

  useEffect(() => {
    if (!isFetching && (currentUser?.id || guestId) && sessionId) {
      if (posthog._isIdentified) {
        const distinctId = posthog.get_property("distinct_id");

        const user = currentUser?.id?.toString() ?? guestId;

        if (user !== distinctId) {
          posthog.identify(currentUser ? currentUser.id?.toString() : guestId, {
            email: currentUser?.email,
            firstName: currentUser?.firstName,
            lastName: currentUser?.lastName,
            planId: currentUser ? currentUser.plan : 0,
            sessionId: sessionId,
          });
        }
      } else {
        posthog.identify(currentUser ? currentUser.id?.toString() : guestId, {
          email: currentUser?.email,
          firstName: currentUser?.firstName,
          lastName: currentUser?.lastName,
          planId: currentUser ? currentUser.plan : 0,
          sessionId: sessionId,
        });
      }
    }
  }, [sessionId, guestId, userId, isAuthenticated, isFetching]);

  return (
    <SessionContext.Provider
      value={{
        sessionId,
        setSessionId,
        guestId,
        setGuestId,
        userId,
        setUserId,
        isAuthenticated,
        setIsAuthenticated,
        utmString,
        setUtmString,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
}
