import MobPlusIndicator from "@/components/MobPlusIndicator";
import Spinner from "@/components/Spinner";
import usePaywall from "@/hooks/usePaywall";
import useSubscriptionPermission from "@/hooks/useSubscriptionPermission";
import cloudflareLoader from "@/lib/cloudflare-images";
import getPaywallConfigFromEntry from "@/lib/getPaywallConfigFromRecipe";
import getSectionNameFromHandle from "@/lib/getSectionNameFromHandle";
import { pushEvent } from "@/lib/gtm";
import { XMarkIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import Image from "next/legacy/image";
import Link from "next/link";
import { useState } from "react";

const EntryThumbnailCompact = ({
  className = "",
  onClick = () => null,
  entry,
  priority = false,
  draggable = false,
  selectable = false,
  showSection = true,
  showRemoveButton = false,
  onRemove = () => null,
}) => {
  const { setPaywall } = usePaywall();
  const { contentIsRestricted, subscriptionPermission, showLockedBadge } =
    useSubscriptionPermission(entry);
  const [isRemoving, setIsRemoving] = useState(false);

  const paywallConfig = getPaywallConfigFromEntry(entry);

  const href = `/${entry?.uri || ""}`;

  const image = entry.image?.[0];

  const handleClick = () => {
    if (contentIsRestricted && !subscriptionPermission.isMobPlusMember) {
      pushEvent("restrictedEntryThumbnailClick", {
        entryId: entry.id,
        entryTitle: entry.title,
        entrySection: entry.sectionHandle,
      });
    }

    onClick();
  };

  const showMobPlusIndicator =
    showLockedBadge && process.env.NEXT_PUBLIC_PAYWALL_ALL_RECIPES !== "true";

  const handleRemoveClick = () => {
    setIsRemoving(true);
    onRemove().finally(() => setIsRemoving(false));
  };

  return (
    (<div
      onClick={handleClick}
      className={clsx("EntryThumbnailCompact", "space-y-2", className)}
    >
      <div className={clsx("relative")}>
        <Link
          href={href}
          className={clsx(
            "group relative block aspect-square overflow-hidden rounded-xl bg-zinc-500",
            {
              "select-none": !selectable,
            }
          )}
          onClick={handleClick}
          draggable={draggable}>

          {!!image && (
            <Image
              src={image.url}
              layout="fill"
              objectFit="cover"
              alt={image.title}
              className={clsx(
                "EntryThumbnailCompact__image",
                "select-none transition-transform ease-out hover:scale-[1.025]"
              )}
              loader={cloudflareLoader}
              draggable={false}
              priority={priority}
              sizes="160px"
            />
          )}

        </Link>
        {showMobPlusIndicator && (
          <div
            className={clsx(
              "EntryThumbnail__badge",
              "absolute left-2.5 top-2.5"
            )}
          >
            <MobPlusIndicator
              size="sm"
              locked={!subscriptionPermission.isMobPlusMember}
            />
          </div>
        )}

        {showRemoveButton && (
          <div className="absolute right-2.5 top-2.5">
            <button
              className={clsx(
                "flex size-7 items-center justify-center rounded-full bg-zinc-900 text-zinc-50 transition ease-out hover:bg-zinc-800"
              )}
              onClick={handleRemoveClick}
              disabled={isRemoving}
            >
              {isRemoving ? (
                <Spinner size="sm" />
              ) : (
                <XMarkIcon className="h-4 w-4" />
              )}
            </button>
          </div>
        )}
      </div>
      <div className={clsx("")}>
        {showSection && (
          <div className={clsx("select-none font-body text-xs text-zinc-400")}>
            {entry.sectionHandle
              ? getSectionNameFromHandle(entry.sectionHandle)
              : ""}
          </div>
        )}
        <Link
          href={href}
          className={clsx(
            "line-clamp-2 select-none font-body text-sm xl:font-medium"
          )}
          onClick={handleClick}>

          {entry.title || (
            <>
              {/* Two lines breaks, to maintain height if this thumbnail is a placeholder */}
              <br />
              <br />
            </>
          )}

        </Link>
      </div>
    </div>)
  );
};

export default EntryThumbnailCompact;
