import algoliasearch from "algoliasearch/lite";

const algoliaClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  process.env.NEXT_PUBLIC_ALGOLIA_API_KEY
);

const searchClient = {
  ...algoliaClient,
  search(requests) {
    // Skip cache during build time, since we don't have access to /api/search/cache
    if (typeof window === "undefined") {
      return algoliaClient.search(requests);
    }

    // Client-side cache logic remains the same
    const isEmptySearch = requests.every(
      ({ params }) =>
        !params.query &&
        (!params.facetFilters || params.facetFilters.length === 0)
    );

    if (isEmptySearch) {
      const indexName = requests[0].indexName;
      const page = requests[0].params.page || 0;
      const hitsPerPage = requests[0].params.hitsPerPage || 20;

      return fetch(
        `/api/search/cache?indexName=${indexName}&page=${page}&hitsPerPage=${hitsPerPage}`
      ).then(async (res) => {
        const cachedResults = await res.json();

        if (!cachedResults) {
          const results = await algoliaClient.search(requests);

          fetch("/api/search/cache", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              indexName,
              page,
              hitsPerPage,
              results,
            }),
          });

          return results;
        }

        return cachedResults;
      });
    }

    return algoliaClient.search(requests);
  },
};

export default searchClient;
