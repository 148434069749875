import GTMEvent from "@/types/GTMEvent";

const navigation = "navigation";

export const navItemClick = {
  category: navigation,
  name: "item",
  action: "click",
  label: "A navigation item has been clicked",
} as GTMEvent;
