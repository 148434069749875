import Loading from "@/components/Loading";
import { useCollectionsContext } from "@/contexts/CollectionsContext";
import useCurrentUser from "@/hooks/useCurrentUser";
import useCurrentUserCollections from "@/hooks/useCurrentUserCollections";
import cloudflareLoader from "@/lib/cloudflare-images";
import { ArrowRightIcon, XMarkIcon } from "@heroicons/react/24/outline";
import * as Dialog from "@radix-ui/react-dialog";
import * as ScrollArea from "@radix-ui/react-scroll-area";
import clsx from "clsx";
import { observer } from "mobx-react";
import Image from "next/legacy/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect } from "react";

const CollectionsOverlay = () => {
  const { currentUser } = useCurrentUser();

  const hasListsMigrated = currentUser?.listsMigrated;

  const router = useRouter();
  const { isOverlayOpen, setIsOverlayOpen } = useCollectionsContext();

  const handleOpenChange = (open: boolean) => {
    setIsOverlayOpen(open);
  };

  useEffect(() => {
    const handleRouteChange = () => {
      setIsOverlayOpen(false);
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => router.events.off("routeChangeComplete", handleRouteChange);
  }, [router.events, setIsOverlayOpen]);

  // Define href, if the user has migrated, use /lists, otherwise use /collections
  const href = hasListsMigrated ? "/lists" : "/collections";

  return (<>
    <Dialog.Root
      defaultOpen
      open={isOverlayOpen}
      onOpenChange={handleOpenChange}
      modal
    >
      <Dialog.Portal>
        <Dialog.Overlay
          className={clsx("fixed inset-0 z-10 bg-zinc-950/50")}
        />
        <Dialog.Content
          className={clsx(
            "top-header fixed bottom-0 right-0 z-20 w-full bg-zinc-950 lg:w-1/2"
          )}
        >
          <div
            className={clsx(
              "h-header flex items-center border-b border-zinc-700 px-6 lg:px-12"
            )}
          >
            <Dialog.Title className={clsx("text-base text-zinc-50")}>
              <Link href={href}>
                Saved Recipes
              </Link>
            </Dialog.Title>

            <Dialog.Close
              className={clsx(
                "h-component w-component ml-auto flex items-center justify-end"
              )}
            >
              <XMarkIcon className="h-6 w-6 text-zinc-50" />
            </Dialog.Close>
          </div>

          <ScrollArea.Root className={clsx("relative h-full")}>
            <ScrollArea.Viewport className={clsx("h-full pb-28")}>
              <div className={clsx("text-zinc-50")}>
                {isOverlayOpen && <_CollectionsList />}
              </div>
            </ScrollArea.Viewport>
            <ScrollArea.Scrollbar
              orientation="vertical"
              className={clsx(
                "group absolute bottom-0 right-0 top-0 z-[88] flex w-3.5 touch-none select-none bg-transparent p-1"
              )}
            >
              <ScrollArea.Thumb
                className={clsx(
                  "w-1.5 flex-1 rounded-full bg-zinc-800 transition-colors ease-out group-hover:bg-white/75"
                )}
              />
            </ScrollArea.Scrollbar>
          </ScrollArea.Root>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  </>);
};

type CollectionsListType = Record<string, never>;

export const _CollectionsList: React.FunctionComponent<
  CollectionsListType
> = () => {
  const { collections, isLoading, error } = useCurrentUserCollections();

  const defaultCollection = collections?.find(
    (collection) => collection.title === "Saved Recipes"
  );

  const remainingCollections = collections?.filter(
    (collection) => collection.title !== "Saved Recipes"
  );

  if (isLoading) {
    return (
      <div className={clsx("py-12")}>
        <Loading />
      </div>
    );
  }

  return (
    <>
      {!!defaultCollection && (
        <_Collection
          key={defaultCollection.id}
          collection={defaultCollection}
          href="/saved-recipes"
          isDefault
        />
      )}
      {remainingCollections.map((collection) => {
        return <_Collection key={collection.id} collection={collection} />;
      })}
    </>
  );
};

const _Collection = ({ isDefault = false, collection, href = null }) => {
  const image = collection.image;

  const title = isDefault ? "All Saved Recipes" : collection.title;

  return (
    (<Link
      key={collection.id}
      href={href || `/collections/${collection.id}`}
      className={clsx(
        "group relative flex items-center border-b border-b-zinc-800 px-6 py-6 last:border-b-0 focus:border-b-zinc-900 lg:px-12"
      )}>

      <div>
        <div className={clsx("relative")}>
          <div
            className={clsx(
              "relative isolate z-10 aspect-square w-18 rounded-md bg-zinc-700"
            )}
          >
            {!!image && (
              <Image
                src={image.url}
                layout="fill"
                alt={collection.title}
                className={clsx("rounded-md")}
                loader={cloudflareLoader}
              />
            )}
          </div>
          <div
            className={clsx(
              "absolute left-1 top-1 z-0 aspect-square w-18 rounded-md border border-zinc-700"
            )}
          ></div>
        </div>
      </div>
      <div className={clsx("flex w-5/6 items-center px-6")}>
        <div className={clsx("line-clamp-2 font-body text-sm lg:text-base")}>
          {title}
        </div>
      </div>
      <div
        className={clsx(
          "ml-auto hidden h-full items-center justify-end opacity-0 group-hover:opacity-100 lg:flex"
        )}
      >
        <ArrowRightIcon className={clsx("h-5 w-5 text-zinc-50")} />
      </div>

    </Link>)
  );
};

export default observer(CollectionsOverlay);
