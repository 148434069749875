import { SubscriptionContext } from "@/contexts/SubscriptionContext";
import { useContext } from "react";

export const defaultSubscriptionPermission = {
  // Features
  canUseShoppingList: false,
  canUseRecipeSaving: false,
  canUseViewRecipeSaving: false,
  canUseNutritionalInfo: false,
  canUseRecipeScaling: false,
  canReviewRecipes: false,
  canUseRecipeStepByStep: false,
  canViewMethodVideos: false,
  canUsePlanner: false,
  // UI
  shouldSeeNewsletterPrompts: true,

  // Content
  canViewMobPlusMagazine: false,
  canViewMobPlusMealPlans: false,
  canViewMobPlusRecipes: false,
  canViewMobPlusDiscounts: false,

  // Member Details
  isMobPlusMember: false,

  // Account controls
  canEditDietarySettings: false,
};

const getContentIsRestricted = (entry, userPlan) => {
  if (entry.accessLevel === "2" && userPlan === 2) return false; // access level 2 is premium and user plan 2 is premium
  if (
    entry.accessLevel === "1" &&
    (userPlan === 3 || userPlan === 2 || userPlan === 4)
  )
    return false; // access level 1 is registered and user plan 3 is free or premium
  if (entry.accessLevel === "0") return false; // access level 0 is public
  return true;
};

const getShowLockedBadge = (entry, userPlan) => {
  if (entry.accessLevel === "2" && (userPlan === 3 || userPlan === 4)) {
    return true;
  }
  return false;
};

const useSubscriptionPermission = (entry, forceRestriction = false) => {
  const context = useContext(SubscriptionContext);
  const { hasHadATrial, plan } = context;

  const contentIsRestricted = forceRestriction
    ? true
    : entry && getContentIsRestricted(entry, plan);

  const showLockedBadge = forceRestriction
    ? true
    : entry && getShowLockedBadge(entry, plan);

  // Default permissions for guests and plan 3 (new members)
  let subscriptionPermission = defaultSubscriptionPermission;

  if (plan === 0) {
    subscriptionPermission = {
      // Features
      canUseShoppingList: false,
      canUseRecipeSaving: false,
      canUseViewRecipeSaving: false,
      canUseNutritionalInfo: false,
      canUseRecipeScaling: false,
      canReviewRecipes: false,
      canUseRecipeStepByStep: false,
      canUsePlanner: false,
      canViewMethodVideos: false,

      // UI
      shouldSeeNewsletterPrompts: true,

      // Content
      canViewMobPlusMagazine: false,
      canViewMobPlusMealPlans: false,
      canViewMobPlusRecipes: false,
      canViewMobPlusDiscounts: false,

      // Member Details
      isMobPlusMember: false,

      // Account controls
      canEditDietarySettings: false,
    };
  } else if (plan === 1) {
    // Legacy pre-Mob+ members still get access to some functionality
    subscriptionPermission = {
      // Features
      canUseShoppingList: true,
      canUseRecipeSaving: false,
      canUseViewRecipeSaving: true,
      canUseNutritionalInfo: false,
      canUseRecipeScaling: false,
      canReviewRecipes: true,
      canUseRecipeStepByStep: true,
      canUsePlanner: false,
      canViewMethodVideos: false,

      // UI
      shouldSeeNewsletterPrompts: true,

      // Content
      canViewMobPlusMagazine: false,
      canViewMobPlusMealPlans: false,
      canViewMobPlusRecipes: false,
      canViewMobPlusDiscounts: false,

      // Member Details
      isMobPlusMember: false,

      // Account controls
      canEditDietarySettings: false,
    };
  } else if (plan === 2) {
    // Mob+ Subscribers get access to everything
    subscriptionPermission = {
      // Features
      canUseShoppingList: true,
      canUseRecipeSaving: true,
      canUseViewRecipeSaving: true,
      canUseNutritionalInfo: true,
      canUseRecipeScaling: true,
      canReviewRecipes: true,
      canUseRecipeStepByStep: true,
      canUsePlanner: true,
      canViewMethodVideos: true,

      // UI
      shouldSeeNewsletterPrompts: true,

      // Content
      canViewMobPlusMagazine: true,
      canViewMobPlusMealPlans: true,
      canViewMobPlusRecipes: true,
      canViewMobPlusDiscounts: true,

      // Member Details
      isMobPlusMember: true,

      // Account controls
      canEditDietarySettings: true,
    };
  }

  return {
    subscriptionPermission,
    contentIsRestricted,
    hasHadATrial,
    plan,
    showLockedBadge,
  };
};

export default useSubscriptionPermission;
