import { showNotification } from "lib/helpers";
import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";
import { makeAutoObservable } from "mobx";

export default class User {
  store = null;

  constructor(store, data) {
    makeAutoObservable(this);

    this.store = store;

    this.setAttributes(data);
  }

  id = null;

  setId = (value) => {
    this.id = value;
  };

  uid = null;

  setUid = (value) => {
    this.uid = value;
  };

  firstName = "";

  setFirstName = (value) => {
    this.firstName = value;
  };

  lastName = "";

  setLastName = (value) => {
    this.lastName = value;
  };

  email = "";

  setEmail = (value) => {
    this.email = value;
  };

  newPassword = "";

  setPassword = (value) => {
    this.password = value;
  };

  currentPassword = "";

  setCurrentPassword = (value) => {
    this.currentPassword = value;
  };

  password = "";

  setNewPassword = (value) => {
    this.newPassword = value;
  };

  gender = "";

  setGender = (value) => {
    this.gender = value;
  };

  avatar = "burger"; // default

  setAvatar = (value) => {
    this.avatar = value;
  };

  city = "";

  setCity = (value) => {
    this.city = value;
  };

  state = "";

  setState = (value) => {
    this.state = value;
  };

  country = "";

  setCountry = (value) => {
    this.country = value;
  };

  dietaryRequirements = [];

  get dietaryRequirementsIds() {
    return this.dietaryRequirements.map((r) => r.id);
  }

  setDietaryRequirements = (value) => {
    this.dietaryRequirements = value;
  };

  objectives = [];

  get objectivesIds() {
    return this.objectives.map((o) => o.id);
  }

  setObjectives = (value) => {
    this.objectives = value;
  };

  referralSources = [];

  get referralSourcesIds() {
    return this.referralSources.map((o) => o.id);
  }

  setReferralSources = (value) => {
    this.referralSources = value;
  };

  referralSourceOther = "";
  setReferralSourceOther = (value) => {
    this.referralSourceOther = value;
  };

  /**
   * Treat these as numbers and not booleans because additional plans may launch in the future.
   *
   * 0 = Guest (Note: this model is accessed via the authStore, so the user is always logged in and never gets a 0 but the usePlan hook defaults to 0)
   * 1 = Legacy Free User
   * 2 = Mob+ User
   * 3 = New Free User
   */
  plan = 0;

  get getPlan() {
    return this.plan;
  }

  setPlan = (value) => {
    this.plan = value;
  };

  /*
   * This is the user's ID as a hashed string. It is used for the intercom integration so that users can't
   * spoof others' accounts.
   */
  hash = "";

  get getHash() {
    return this.hash;
  }

  setHash = (value) => {
    this.hash = value;
  };

  // This is basically "is admin" but called role to disguise the attribute somewhat
  // It is sent from currentUser.json as a jwt; the jwt is validated before being set on the user
  role = false;

  get getRole() {
    return this.role;
  }

  setRole = (value) => {
    this.role = value;
  };

  dateCreated = "";

  get getDateCreated() {
    return this.dateCreated;
  }

  setDateCreated = (value) => {
    this.dateCreated = value;
  };

  types = [];

  get typesIds() {
    return this.types.map((t) => t.id);
  }

  setTypes = (value) => {
    this.types = value;
  };

  cuisines = [];

  get cuisinesIds() {
    return this.cuisines.map((c) => c.id);
  }

  setCuisines = (value) => {
    this.cuisines = value;
  };

  occasions = [];

  get occasionsIds() {
    return this.occasions.map((o) => o.id);
  }

  setOccasions = (value) => {
    this.occasions = value;
  };

  types = [];

  get typesIds() {
    return this.types.map((o) => o.id);
  }

  setTypes = (value) => {
    this.types = value;
  };

  recipeCategories = [];

  get recipeCategoriesIds() {
    return this.recipeCategories.map((o) => o.id);
  }

  setRecipeCategories = (value) => {
    this.recipeCategories = value;
  };

  // Crreate a helper getter to show whether the user has *any* preferred categories,
  // so types, cuisines, occasions, and recipeCategories
  get hasPreferredCategories() {
    return (
      this.types.length > 0 ||
      this.cuisines.length > 0 ||
      this.occasions.length > 0 ||
      this.recipeCategories.length > 0
    );
  }

  setAttributes = (data) => {
    for (const [key, value] of Object.entries(data)) {
      this[key] = value;
    }
  };

  hasCompletedOnboarding = 0;

  setHasCompletedOnboarding = (value) => {
    this.hasCompletedOnboarding = value;
  };

  cancellationDate = null;

  setCancellationDate = (value) => {
    this.cancellationDate = value;
  };

  howDidYouHearAboutMob = "";
  setHowDidYouHearAboutMob = (value) => {
    this.howDidYouHearAboutMob = value;
  };

  howDidYouHearAboutMobOther = "";
  setHowDidYouHearAboutMobOther = (value) => {
    this.howDidYouHearAboutMobOther = value;
  };

  otherObjective = "";
  setOtherObjective = (value) => {
    this.otherObjective = value;
  };

  otherCategory = "";
  setOtherCategory = (value) => {
    this.otherCategory = value;
  };

  dateOfBirth = "";
  setDateOfBirth = (value) => {
    this.dateOfBirth = value;
  };

  save = (password = null) => {
    console.log(this.referralSourcesIds, this.objectivesIds);
    const data = {
      userId: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      newPassword: this.newPassword,
      currentPassword: this.currentPassword,
      password: this.password,
      [`fields[city]`]: this.city,
      [`fields[state]`]: this.state,
      [`fields[country]`]: this.country,
      [`fields[dietaryRequirements]`]: this.dietaryRequirementsIds.length
        ? this.dietaryRequirementsIds
        : [null],
      [`fields[objectives]`]: this.objectivesIds.length
        ? this.objectivesIds
        : [null],
      [`fields[objectiveOther]`]: this.otherObjective,
      [`fields[cuisines]`]: this.cuisinesIds.length ? this.cuisinesIds : [null],
      [`fields[types]`]: this.typesIds.length ? this.typesIds : [null],
      [`fields[recipeCategories]`]: this.recipeCategoriesIds.length
        ? this.recipeCategoriesIds
        : [null],
      [`fields[categoryOther]`]: this.otherCategory,
      [`fields[occasions]`]: this.occasionsIds,
      [`fields[avatar]`]: this.avatar,
      [`fields[hasCompletedOnboarding]`]: this.hasCompletedOnboarding ? 1 : 0, // Craft requires this as 0 or 1 instead of true or false. Perhaps it's stringifying to false becomes "false" which is treated as true?
      [`fields[howDidYouHearAboutMob]`]: this.howDidYouHearAboutMob,
      [`fields[howDidYouHearAboutMobOther]`]: this.howDidYouHearAboutMobOther,
      [`fields[dateOfBirth]`]: this.dateOfBirth,
      [`fields[referralSource]`]: this.referralSourcesIds,
      [`fields[referralSourceOther]`]: this.referralSourceOther,
      action: "users/save-user",
    };

    const status = this.store.apiStore.axiosInstance({
      method: "POST",
      data: {
        ...omitBy(data, isNil),
        action: "users/save-user",
      },
    });

    status.then(
      (response) => {
        if (response.data.success) {
          // Set the new csrf token value if one exists...
          if (response.data.csrfTokenValue)
            this.store.apiStore.setCsrfTokenValue(response.data.csrfTokenValue);

          // ... otherwise fetch it
          if (!response.data.csrfTokenValue)
            this.store.apiStore.fetchCsrfToken();
        }
        if (response.data.error) return Promise.reject(response.data.error);
      },
      (error) => {
        showNotification(`There was a problem saving your details: ${error}`);
      }
    );

    status.then(() => {
      // Remove the password, and newpassword values
      this.setPassword("");
      this.setNewPassword("");
    });

    return status;
  };

  delete = () => {
    const status = this.store.apiStore.axiosInstance({
      method: "POST",
      url: "actions/site-module/site/delete-user",
      data: {
        userId: this.id,
      },
    });

    status.then(
      (response) => {
        if (response.data.success) {
          // Set the new csrf token value if one exists...
          if (response.data.csrfTokenValue)
            this.store.apiStore.setCsrfTokenValue(response.data.csrfTokenValue);

          // ... otherwise fetch it
          if (!response.data.csrfTokenValue)
            this.store.apiStore.fetchCsrfToken();
        }
        if (response.data.error) return Promise.reject(response.data.error);
      },
      (error) => {
        showNotification(`There was a problem deleting your account: ${error}`);
      }
    );

    return status;
  };
}
