import DraggableScrollArea from "@/components/DraggableScrollArea";
import cloudflareLoader from "@/lib/cloudflare-images";
import clsx from "clsx";
import Image from "next/legacy/image";
import Link from "next/link";

const ChefCarousel = ({
  chefs,
  theme = "dark",
  className = "px-6 lg:px-12",
  size = "lg",
}) => {
  return (
    (<DraggableScrollArea>
      <div
        className={clsx(
          "ChefCarousel",
          className,
          "flex space-x-4 lg:space-x-6"
        )}
      >
        {chefs.map((chef) => {
          const chefCategory = chef.chef?.[0];

          // The chef might not be present or even active, so return null if that's the case
          if (!chefCategory) return null;

          const image = chefCategory.image?.[0];

          return (
            (<div
              key={chef.id}
              className={clsx("ChefCarousel__cell", "select-none ", {
                "w-24 xl:w-32": size === "lg",
                "w-20 xl:w-24": size === "md",
                "w-16 xl:w-20": size === "sm",
              })}
            >
              <Link
                href={`/chefs/${chefCategory.slug}`}
                className={clsx("group flex w-full")}
                draggable={false}>

                <div className={clsx("w-full space-y-2")}>
                  <div
                    className={clsx(
                      "relative isolate aspect-square overflow-hidden rounded-full bg-white"
                    )}
                  >
                    {!!image && (
                      <Image
                        src={image.url}
                        layout="fill"
                        objectFit="cover"
                        alt={image.title}
                        draggable={false}
                        className={clsx(
                          "EntryThumbnail__image",
                          "select-none transition-transform ease-out group-hover:scale-[1.025]"
                        )}
                        loader={cloudflareLoader}
                        sizes="128px"
                      />
                    )}
                  </div>
                  <div
                    className={clsx("text-center font-body text-sm", {
                      "group-hover:text-zinc-950": theme === "dark",
                      "text-zinc-50 group-hover:text-zinc-200":
                        theme === "light",
                    })}
                  >
                    {chefCategory.displayName || chefCategory.title}
                  </div>
                </div>

              </Link>
            </div>)
          );
        })}
      </div>
    </DraggableScrollArea>)
  );
};

export default ChefCarousel;
