import Login from "@/components/Login";
import { loginPopoverToggleClicked } from "@/gtmEvents/globalHeader";
import pushToDataLayer from "@/lib/pushToDataLayer";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import * as Popover from "@radix-ui/react-popover";
import clsx from "clsx";
import Link from "next/link";
import { useState } from "react";

const AuthControl = ({ theme = "light" }) => {
  const [isOpen, setIsOpen] = useState(false);

  const Icon = isOpen ? ChevronUpIcon : ChevronDownIcon;

  return (
    (<div className={clsx("AuthControl", "h-component flex")}>
      <Popover.Root onOpenChange={setIsOpen}>
        <Popover.Trigger
          className={clsx(
            "flex h-full items-center rounded-full transition ease-out lg:space-x-2 lg:px-4 lg:pr-3",
            {
              "bg-zinc-200 text-zinc-800 hover:bg-zinc-300 hover:text-zinc-950":
                theme === "light" && !isOpen,
              "bg-zinc-950 text-zinc-50 hover:bg-zinc-900 hover:text-zinc-100":
                theme === "light" && isOpen,
              "bg-zinc-800 text-white hover:bg-zinc-700 hover:text-zinc-50":
                theme === "dark" && !isOpen,
              "bg-zinc-100 text-zinc-800 hover:bg-zinc-200 hover:text-zinc-950":
                theme === "dark" && isOpen,
            }
          )}
          onClick={() => !isOpen && pushToDataLayer(loginPopoverToggleClicked)}
        >
          <div className={clsx("flex items-center whitespace-nowrap")}>
            <div className={clsx("flex font-body text-sm ")}>Log In</div>
          </div>
          <div className={clsx("flex items-center px-1 lg:px-0")}>
            <Icon className={clsx("h-3 w-3")} />
          </div>
        </Popover.Trigger>

        <Popover.Portal className={clsx("z-[51]")}>
          <Popover.Content
            className={clsx(
              "z-[52] w-80 rounded-2xl bg-zinc-900 text-zinc-50 shadow-lg"
            )}
            sideOffset={6}
            collisionPadding={24}
          >
            <Popover.Arrow
              className={clsx("fill-zinc-950")}
              width={15}
              height={7}
            />
            <div>
              <div className={clsx("space-y-2 border-b border-zinc-700 p-6")}>
                <div>
                  <Login darkMode />
                </div>
                <div className={clsx("text-center")}>
                  <Link
                    href="/signup"
                    className={clsx(
                      "mx-auto font-body text-sm text-zinc-300 underline transition ease-out hover:text-zinc-50"
                    )}>
                    
                      Create an account
                    
                  </Link>
                </div>
                <div className={clsx("text-center")}>
                  <Link
                    href="/forgotten"
                    className={clsx(
                      "mx-auto font-body text-sm text-zinc-300 underline transition ease-out hover:text-zinc-50"
                    )}>
                    
                      I have forgotten my password
                    
                  </Link>
                </div>
              </div>
            </div>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>)
  );
};

export default AuthControl;
