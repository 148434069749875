import { pushEvent } from "@/lib/gtm";
import { showNotification } from "lib/helpers";
import { makeAutoObservable } from "mobx";
import Link from "next/link";
export default class Menu {
  store = null;

  isSaving = false;

  id = null;

  dateCreated = null;

  title = "";

  setTitle = (value) => {
    this.title = value;
  };

  setIsSaving = (value) => {
    this.isSaving = value;
  };

  get updatedMessage() {
    return (
      (<div>
        <Link href={`/collections/${this.id}`}>
          {this.title}
        </Link>{" "}has been updated
              </div>)
    );
  }

  recipes = [];

  constructor(store, { id, title, recipes, dateCreated }) {
    makeAutoObservable(this);

    this.store = store;

    if (id) this.id = id;
    if (title) this.title = title;
    if (recipes) this.recipes = recipes;
    if (dateCreated) this.dateCreated = dateCreated;
  }

  get link() {
    return this.isDefault
      ? { href: "/saved-recipes", as: "/saved-recipes" }
      : { href: "/collections/[id]", as: `/collections/${this.id}` };
  }

  addRecipe = async (recipe) => {
    this.recipes.push(recipe);

    // Display a notification
    const Message = () => (
      <div>
        Saved <a href={`/recipes/${recipe.slug}`}>{recipe.title}</a> to{" "}
        <a href={this.link.as}>{this.title}</a>
      </div>
    );

    showNotification(<Message />);

    try {
      const response = await this.store.apiStore.axiosInstance({
        method: "POST",
        data: {
          elementId: recipe.id,
          listId: this.id,
          action: "wishlist/items/add",
        },
      });

      // Send gtag event
      await pushEvent("saveRecipe", {
        recipeId: parseInt(recipe.id, 10),
        recipeTitle: recipe.title,
        menuId: this.id,
        menuTitle: this.title,
      });

      return await response;
    } catch (error) {
      console.error(error);
    }
  };

  getRecipeIndex = (recipe) =>
    this.recipes.findIndex(
      ({ id }) => parseInt(id, 10) === parseInt(recipe.id, 10)
    );

  get images() {
    const images = [];

    this.recipes.map((r) => {
      if (images.length < 4) images.push(r.image);
    });

    return images;
  }

  removeRecipe = (recipe) => {
    this.recipes.splice(this.getRecipeIndex(recipe), 1);

    return this.store.apiStore
      .axiosInstance({
        method: "POST",
        data: {
          elementId: recipe.id,
          listId: this.id,
          action: "wishlist/items/remove",
        },
      })
      .then(
        (response) => response,
        (error) => Promise.reject(error)
      );
  };

  isInMenu = (recipe) =>
    this.recipes.length > 0 ? this.getRecipeIndex(recipe) > -1 : false;

  toggleRecipe = async (recipe) => {
    if (this.isInMenu(recipe)) {
      return this.removeRecipe(recipe);
    }
    return this.addRecipe(recipe);
  };

  get recipeIds() {
    return this.recipes.map((r) => r.id);
  }

  save = () => {
    this.setIsSaving(true);

    const handleError = (error) => {
      // Show an error
      showNotification(`There was a problem saving your menu: ${error}`);
    };

    return this.store.apiStore
      .axiosInstance({
        method: "POST",
        data: {
          listId: this.id ? this.id : null,
          title: this.title,
          items: this.itemIds,
          typeId: 1,
          action: "wishlist/lists/create",
        },
      })
      .then(
        (response) => {
          if (response.data.error) {
            handleError(response.data.error);
            return Promise.reject(response.data.error);
          }
          if (response.data.id) {
            this.id = response.data.id;
            return response;
          }
        },
        (error) => {
          handleError(error);
        }
      )
      .finally(() => {
        this.setIsSaving(false);
      });
  };

  delete = () => {
    const handleError = (error) => {
      // Show an error
      showNotification(`There was a problem deleting your menu: ${error}`);
    };

    return this.store.apiStore
      .axiosInstance({
        method: "POST",
        data: {
          listId: this.id,
          action: "wishlist/lists/delete",
        },
      })
      .then(
        (response) => {
          if (response.data.error) {
            handleError(response.data.error);
            return Promise.reject(response.data.error);
          }
          if (response.data.success) {
            this.store.remove(this);
            return response;
          }
        },
        (error) => {
          handleError(error);
        }
      );
  };

  get isDefault() {
    return this.title === this.store.defaultTitle;
  }
}
