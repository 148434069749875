import ChefToken from "@/components/ChefToken";
import Link from "next/link";

const NavBarServer = ({ primaryNavigation }) => {
  return (
    <div className="flex items-center">
      <nav aria-label="Main" data-orientation="horizontal" dir="ltr">
        <div style={{ position: "relative" }}>
          <ul
            data-orientation="horizontal"
            className="relative z-50 flex pl-8"
            dir="ltr"
          >
            {Array.isArray(primaryNavigation) &&
              primaryNavigation.map((tab) => (
                <li
                  key={tab.title}
                  className="border-zinc-800 hover:border-b-2"
                >
                  <button
                    className="px-4 pb-6 font-body text-sm"
                    id="radix-4-trigger-radix-5"
                    data-state="closed"
                    aria-expanded="false"
                    aria-controls="radix-4-content-radix-5"
                    data-radix-collection-item=""
                  >
                    {tab.title}
                  </button>
                  <div
                    id="radix-4-content-radix-5"
                    aria-labelledby="radix-4-trigger-radix-5"
                    data-orientation="horizontal"
                    data-state="closed"
                    className="fixed inset-x-0 top-full z-50 border-t border-gray-100 bg-white p-6"
                    dir="ltr"
                    style={{ pointerEvents: "none" }}
                  >
                    <div className="mx-auto max-w-screen-xl">
                      {tab.title === "Our Chefs" ? (
                        <div className="grid grid-cols-3 gap-y-2">
                          {tab.children?.map((chef) => (
                            <div
                              key={chef.id}
                              className="flex items-center py-2"
                            >
                              {chef.element?.image?.length > 0 ? (
                                <ChefToken
                                  chef={{
                                    id: chef.element.id,
                                    slug: chef.nodeUri,
                                    uri: chef.nodeUri,
                                    title: chef.title,
                                    image: {
                                      id: chef.element.image[0]?.id,
                                      url: chef.element.image[0]?.url,
                                    },
                                  }}
                                  size="lg"
                                  theme="light"
                                />
                              ) : (
                                <span className="font-body text-sm text-gray-500">
                                  {chef.title}
                                </span>
                              )}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="grid min-h-[24rem] grid-cols-4 gap-x-4">
                          {tab.children?.map((category) => (
                            <div key={category.id} className="space-y-2">
                              <h3 className="font-semibold text-gray-800">
                                {category.title}
                              </h3>
                              <ul className="space-y-1">
                                {category.children?.map((link) => (
                                  <li key={link.id}>
                                    <Link
                                      href={`/${link.nodeUri}`}
                                      className="font-body text-sm text-gray-500 hover:underline"
                                    >
                                      {link.title}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default NavBarServer;
